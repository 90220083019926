/* eslint-disable no-undef */
/**
 * 支付模块（新版）
 * @author SnowRock
 * 2021-05-13
 */

import { fetchModulesPayParams, fetchPayParamsByModule } from '@/services/comeOn'
import { isWeChat } from '@/utils/common/validate'

/**
 * 获取生活服务支付模块的支付信息业务
 * 2021-05-13 18:34:13
 * @author SnowRock
 * @param moduleId 业务模块ID.1-视频充值 2-视频充值 3-机票支付 4-小桔加油支付
 * @param paymentType 支付方式 2-微信支付 4-支付宝支付 10-余额支付 5-小程序支付 非必填 默认微信支付 2
 * @param arg 额外参数
 */
export async function getModulePaymentInfo(moduleId, paymentType = 5, arg) {
  const moduleInfoRes = await fetchModulesPayParams({ moduleId })
  const moduleInfoResCode = moduleInfoRes.code
  const moduleIds = moduleInfoRes.data.moduleId
  const moduleInfo = moduleInfoRes.data.list.filter(i => i.payType === paymentType)[0]
  if (moduleInfoResCode === 200) {
    if (!moduleInfo) {
      console.log('无此支付方式')
      throw new Error('无支付方式')
    }
    const { payType, isOriginal, payVersion } = moduleInfo
    const res = await fetchPayParamsByModule({
      moduleId: moduleIds,
      payType: payType,
      isOriginal: isOriginal,
      payVersion: payVersion,
      ...arg
    })
    if (res.code !== 200) {
      console.log('fetchPayParamsByModule Error')
      throw new Error(res.msg)
    }
    return {
      code: 200,
      data: {
        moduleId: moduleIds,
        ...res.data,
        ...moduleInfo
      }
    }
  } else {
    console.log('获取支付信息错误')
    throw new Error('无支付方式')
  }
}

/**
 * 微信支付模块
 * 2021-05-17 14:23:01
 * @author SnowRock
 */
function onBridgeReady(params, success, fail) {
  const { appId, timeStamp, nonceStr, signType, paySign } = params
  var timestamp = Math.round(timeStamp).toString()
  WeixinJSBridge.invoke(
    'getBrandWCPayRequest',
    {
      appId: appId, // 公众号名称，由商户传入
      timeStamp: timestamp, // 时间戳，自1970年以来的秒数
      nonceStr: nonceStr, // 随机串
      package: params.package,
      signType: signType, // 微信签名方式：
      paySign: paySign, // 微信签名
      jsApiList: ['chooseWXPay']
    },
    function(res) {
      if (res.err_msg === 'get_brand_wcpay_request:ok') {
        // 使用以上方式判断前端返回,微信团队郑重提示：
        // res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
        success && success(res)
      } else if (res.err_msg === 'get_brand_wcpay_request:cancel') {
        fail && fail({
          res, msg: 'cancel'
        })
      } else {
        fail && fail({
          res, msg: 'fail'
        })
      }
    })
}

/**
 * 微信支付
 * 2021-05-17 14:49:29
 * @author SnowRock
 * @param params 支付需要用的所有参数
 * @param params.timeStamp 时间戳，自1970年以来的秒数
 * @param params.appId 公众号Id，由商户传入
 * @param params.nonceStr 随机串
 * @param params.package 统一下单接口返回的prepay_id参数值，提交格式如：prepay_id=***
 * @param params.signType 微信签名方式
 * @param params.paySign 微信签名
 */
export function weChatPay(params) {
  return new Promise((resolve, reject) => {
    if (!isWeChat()) {
      // 非微信环境无法调用方法
      console.log('非微信环境无法调用方法')
      reject({
        res: null, msg: 'fail'
        // res: null, msg: 'isNotWeChat'
      })
      return
    }
    if (typeof WeixinJSBridge === 'undefined') {
      if (document.addEventListener) {
        document.addEventListener('WeixinJSBridgeReady', () => {
          onBridgeReady(params, resolve, reject)
        }, false)
      } else if (document.attachEvent) {
        document.attachEvent('WeixinJSBridgeReady', () => {
          onBridgeReady(params, resolve, reject)
        })
        document.attachEvent('onWeixinJSBridgeReady', () => {
          onBridgeReady(params, resolve, reject)
        })
      }
    } else {
      onBridgeReady(params, resolve, reject)
    }
  })
}

/**
 * 支付宝支付根据页面内容进行支付
 * 2021-07-30 16:23:46
 * @author SnowRock
 */
export function aliPayByForm(formContent) {
  const template = `<div id="aliPay">${formContent}</div>`
  const aliPayElement = new DOMParser().parseFromString(template, 'text/html').querySelector('#aliPay')
  document.body.appendChild(aliPayElement)
  aliPayElement.getElementsByTagName('form')[0].submit()
  // 处理清除页面信息
  setTimeout(() => {
    document.body.removeChild(aliPayElement)
  }, 100)
}
